<template>
  <div>
    <v-container fluid class="ml-0">
      <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col>
          <v-card class="card-shadow border-radius-xl" elevation="2">
            <div class="card-header-padding">
              <div class="font-weight-bold text-h3 text-typo mb-0 text-center">
                <date-range-picker
                  v-model="dateRange"
                  :linked-calendars="false"
                  :locale-data="localData"
                  :autoApply="false"
               />
                <v-text-field label="Da"></v-text-field>
                <v-text-field label="A"></v-text-field>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pieChart
            :tot_fatturato="fatturato"
            :tot_bottiglie="bottiglieVendute"
            :percentuali="percentage"
            >
          </pieChart>
        </v-col>
        <v-col>
          <pieChart2 :data="priceRangeSales" :price_cost="total_cost" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <mixedChart :dataMixed="mixedData" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <warehouseData :warehouseData="warehousesData"  />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";
import 'moment/locale/it';
import pieChart from "@/views/Pages/Reports/VVPieChart.vue"
import pieChart2 from "@/views/Pages/Reports/VVPieChart2.vue"
import mixedChart from "@/views/Pages/Reports/VVMixedChart.vue"
import StatsService from "@/services/stats.service";
import warehouseData from "@/views/Pages/Reports/VVMixedChartMagazzino.vue"

export default {
  name:'Analytics',
  components: {
    DateRangePicker,
    pieChart,
    pieChart2,
    mixedChart,
    warehouseData
  },
  data () {
    return {
      progress: false,
      // datepicker
      date: "", // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      search: null,
      dateRange: {
          startDate:'',
          endDate: '',
      },
      localData: {
        format: 'dd-mmm-yyyy',
        separator: ' | ',
        applyLabel: 'Applica',
        cancelLabel: 'Cancella',
        fromLabel: 'Da',
        toLabel: 'A',
        customRangeLabel: 'Personalizzato',
        weekLabel: 'Sett',
        daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        monthNames: [
          'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
          'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
        ],
        firstDay: 1,
        todayLabel: 'Oggi',
        yesterdayLabel: 'Ieri'
      },
      // dati
      fatturato:'0.00',
      bottiglieVendute:0,
      total_cost:0,
      percentage:[],
      quantity:0,
      priceRangeSales:[],
      colorMapping: {
        "10-20€":"#03A9F4", "20-30€":"#e91e63", "30-40€":"#3A416F", "40-50€":"#a8b8d8"
      },
      mixedData:{},
      warehousesData:{}
    }
  },
  mounted () {
    console.log('moment local', moment.locale())
    moment.locale('it')
    console.log('moment local', moment.locale())
  },
  watch: {
    dateRange(val) {
      this.getAnalytics()
      console.log(val)
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() restituisce mesi da 0 (gennaio) a 11 (dicembre)
      const year = date.getFullYear().toString().substring(2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      // Formatta la data nel nuovo formato
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).locale('it').format('dddd, DD MMMM YYYY') : ''
    }
  },
  methods: {
    formatCurrency(value) {
    // Crea un formattatore per numeri in euro
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        // Qui puoi specificare altre opzioni, come il numero minimo di cifre decimali
        minimumFractionDigits: 2
      });

    // Utilizza il formattatore per convertire il numero in una stringa formattata
      return formatter.format(value);
    },
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() > new Date()
        }
        return classes
    },
    getAnalytics() {
  const params = {
    store_id: this.$store.state.auth.user.store_id,
    start_date: this.dateRange.startDate.toISOString(),
    end_date: this.dateRange.endDate.toISOString(),
  };
  StatsService.getAnalyticsByDate(params).then(resp => {
    this.fatturato = this.formatCurrency(resp.data.total_incasso);
    this.bottiglieVendute = resp.data.total_bottles_sold;
    this.total_cost = resp.data.total_price_cost;
    this.percentage = Object.entries(resp.data.wine_type_percentages).map(([key, value]) => ({
      key,
      percent: value.percentage.toFixed(2),
      total_quantity: value.total_quantity
    }));
    this.priceRangeSales = resp.data.price_range_sales.map(item => ({
      ...item,
      color: this.colorMapping[item.range] || '#000'
    }));
    this.mixedData = resp.data.data_grafico_barre;

    // Aggiungi questo log per verificare i dati
    console.log("Warehouse Data:", resp.data.warehouse_data);

    this.warehousesData = resp.data.warehouse_data;  // Passa i dati al componente WarehouseData
  });
}
  }
}
</script>
<style scoped>
.custom_date_range {
  min-height: 40px;
  min-width: 300px;
  border: 2px;
}

</style>